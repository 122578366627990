import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { mapEdgesToNodes } from '../lib/helpers';
import Layout from '../components/Layout';
import BlockContent from '@sanity/block-content-to-react';
import Img from 'gatsby-image';
import imageUrlBuilder from '@sanity/image-url';
import sanityClient from '../components/sanity-client';

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query BlogPageQuery {
      posts: allSanityPost(sort: { fields: [publishedAt], order: DESC }) {
        edges {
          node {
            id
            publishedAt(locale: "de", formatString: "DD.MMMM.YYYY")
            title
            _rawSubText
            slug {
              current
            }
            mainImage {
              asset {
                fluid(maxHeight: 200, maxWidth: 400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            author {
              name
            }
          }
        }
      }
    }
  `);

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts);

  const builder = imageUrlBuilder(sanityClient);

  const urlFor = source => {
    return builder.image(source);
  };

  const serial = {
    types: {
      imageBlog: props => {
        return (
          <span
            className={`image ${
              props.node.direction === 'Links'
                ? 'left'
                : props.node.direction === 'Voll'
                ? 'fit'
                : 'right'
            }`}
            style={{ zIndex: 1 }}
          >
            <img
              src={urlFor(props.node.image.asset).url()}
              alt={props.node.image.asset._ref}
            />
          </span>
        );
      },
    },
  };

  return (
    <Layout fullMenu>
      <section id="wrapper">
        <header>
          <div className="inner">
            <h2>Blog</h2>
          </div>
        </header>

        <div className="wrapper">
          <div className="inner">
            <section className="features">
              {postNodes.map(post => (
                <article key={post.id}>
                  <Link to={`../${post.slug.current}`} className="image">
                    {post.mainImage && (
                      <Img fluid={post.mainImage.asset.fluid} alt="" />
                    )}
                  </Link>
                  <h3 className="major">{post.title}</h3>
                  <span style={{ fontSize: 18 }}>
                    <b>{`${post.author.name} - ${post.publishedAt}`}</b>
                  </span>
                  <div style={{ maxHeight: 320 }}>
                    <BlockContent
                      blocks={post._rawSubText}
                      serializers={serial}
                    />
                    <Link to={`../${post.slug.current}`} className="special">
                      Mehr dazu
                    </Link>
                  </div>
                </article>
              ))}
            </section>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default BlogPage;
